<template>
  <!-- 修改商品 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- 搜索关键词 -->
    <el-form ref="form" :model="searchData" inline label-width="">
      <el-form-item label="">
        <el-input style="width:300px;" v-model="searchData.keywords" size="small" placeholder="请输入商品ID进行搜索"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button type="" size="small" @click="$router.push({ name: 'GoodsList' })">商品列表</el-button>
      </el-form-item>
    </el-form>
    <!-- 搜索内容 -->
    <!--    <div class="search-content" v-show="false">-->
    <!--      <el-descriptions direction="vertical" :column="5" border>-->
    <!--        <el-descriptions-item label="商品ID" label-class-name="my-label">{{ goodsInfo.id }}</el-descriptions-item>-->
    <!--        <template v-if="goodsInfo.category">-->
    <!--          <el-descriptions-item label="分类" label-class-name="my-label">{{ goodsInfo.category.categoryName }}</el-descriptions-item>-->
    <!--        </template>-->
    <!--        <el-descriptions-item label="名称" label-class-name="my-label">{{ goodsInfo.goodsTitle }}</el-descriptions-item>-->
    <!--        <el-descriptions-item label="价格" label-class-name="my-label">{{ goodsInfo.rentPrice }}</el-descriptions-item>-->
    <!--        <el-descriptions-item label="状态" label-class-name="my-label">{{ goodsInfo.status == 1 ? '上架' : '下架' }}</el-descriptions-item>-->
    <!--      </el-descriptions>-->
    <!--      <el-form class="formData" ref="formData" :model="formData" label-width="100px">-->
    <!--        <el-form-item label="选择规格：">-->
    <!--          <div class="sku" v-for="(sku, i) in goodsInfo.skuAttrGroupList" :key="i">-->
    <!--            <span class="sku-name">{{ sku.group }}</span>-->
    <!--            &lt;!&ndash; <span class="sku-name">{{sku.group}}</span> &ndash;&gt;-->
    <!--            <div class="sku-value">-->
    <!--              <span v-for="(item, i) in sku.valueList" :key="i">{{ item }}</span>-->
    <!--              &lt;!&ndash; <el-radio-group v-model="formData.sku" size="mini">-->
    <!--                <el-radio-button v-for="item in 3" :key="item" :label="item"></el-radio-button>-->
    <!--              </el-radio-group> &ndash;&gt;-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </el-form-item>-->
    <!--        &lt;!&ndash; <el-form-item label="配件：">-->
    <!--          <el-checkbox-group v-model="formData.checkList">-->
    <!--            <el-checkbox label="保险：￥398"></el-checkbox>-->
    <!--            <el-checkbox label="复选框 B"></el-checkbox>-->
    <!--            <el-checkbox label="复选框 C"></el-checkbox>-->
    <!--          </el-checkbox-group>-->
    <!--        </el-form-item> &ndash;&gt;-->
    <!--        <el-form-item label="保险金：">-->
    <!--          {{ goodsInfo.insurancePrice }}-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="溢价金：">-->
    <!--          {{ goodsInfo.overflowPrice }}-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="选择分期数："> {{ goodsInfo.billPeriodNum }} 期 </el-form-item>-->
    <!--        <el-form-item label="首付期数：">-->
    <!--          <span style="margin-right:20px;" v-for="(item, i) in goodsInfo.prepayPeriodList" :key="i">第{{ item }}期</span>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="首付总金额：">-->
    <!--          123-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--      <div class="submit-btn">-->
    <!--        <el-button type="" size="small" @click="$router.push({ name: 'OrderDetail', params: { id: $route.params.id } })"> 取消</el-button>-->
    <!--        <el-button type="primary" size="small" @click="updateOrderGoods">提交</el-button>-->
    <!--      </div>-->
    <!--      <div style="height:50px;"></div>-->
    <!--    </div>-->
    <!-- sku -->
    <template v-if="showGoods">
      <div class="sku-list">
        <div class="item-row">
          <div class="item-clu"></div>
          <div class="item-clu">描述</div>
          <div class="item-clu">规格</div>
          <div class="item-clu">总租金</div>
          <div class="item-clu">每期租金</div>
          <div class="item-clu">期数</div>
          <div class="item-clu">首付期数</div>
          <div class="item-clu">保险金</div>
          <div class="item-clu">溢价金</div>
          <div class="item-clu">封面</div>
        </div>
        <div class="item-row" v-for="sku in skuList" :key="sku.id">
          <div class="item-clu">
            <el-radio v-model="formData.skuId" :label="sku.id" @change="skuChange(sku)">{{ '' }}</el-radio>
          </div>
          <div class="item-clu">{{ sku.skuTitle }}</div>
          <div class="item-clu">
            <div>
              <div v-for="(item, i) in sku.skuAttrList" :key="i">{{ item.group }}：{{ item.value }}</div>
            </div>
          </div>
          <div class="item-clu">{{ sku.rentPrice }}</div>
          <div class="item-clu">{{ sku.eachRentPrice }}</div>
          <div class="item-clu">{{ goodsInfo.billPeriodNum }} 期</div>
          <div class="item-clu">
            <span style="margin-right:20px;" v-for="(item, i) in goodsInfo.prepayPeriodList" :key="i">第{{ item }}期</span>
          </div>
          <div class="item-clu">{{ sku.insurancePrice }}</div>
          <div class="item-clu">{{ sku.overflowPrice }}</div>
          <div class="item-clu">
            <el-image style="width: 60px; height: 60px" :src="sku.coverImageUrl" :preview-src-list="[sku.coverImageUrl]"> </el-image>
          </div>
        </div>
      </div>
      <div class="combo">
        <span style="margin-right:10px;fontWeight:600">套餐类型：</span>
        <SelectDict v-model="formData.goodsCombo" dictCode="goods:combo"></SelectDict>
      </div>
      <div class="service" v-if="skuExtraServiceList && skuExtraServiceList.length > 0">
        <div class="left" style="margin-right:10px;font-weight:600">增值服务：</div>
        <div class="right">
          <el-checkbox-group v-model="formData.extraServiceCodeList">
            <el-checkbox v-for="(item, i) in skuExtraServiceList" :key="i" :label="item.code">
              {{ item.name }}（{{ item.price }}元）
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="submit-btn">
        <el-button
          type=""
          size="small"
          @click="$router.push({ name: 'ReviewDetail', params: { id: $route.params.id }, query: { memberId: $route.query.memberId } })"
        >
          取消</el-button
        >
        <el-button type="primary" size="small" @click="updateOrderGoods">提交</el-button>
      </div>
    </template>

    <div style="height:50px;"></div>
  </div>
</template>

<script>
import { getGoodsDetailAPI, updateOrderGoodsAPI } from './api'
import SelectDict from '@/views/components/select-combo-update-goods.vue'
export default {
  name: 'UpdateGoods',
  components: { SelectDict },
  data() {
    return {
      showGoods: false,
      searchData: {
        keywords: ''
      },
      formData: {
        orderNo: '',
        skuId: '',
        goodsCombo: '',
        extraServiceCodeList: []
      },
      goodsInfo: {},
      skuList: [],
      skuExtraServiceList: []
    }
  },
  computed: {},
  mounted() {},

  methods: {
    async search() {
      const { keywords } = this.searchData
      if (!keywords) return this.$message.error('请输入商品ID')
      const res = await getGoodsDetailAPI(keywords)
      this.skuList = res.skuList
      this.goodsInfo = res
      this.showGoods = true
      this.formData = {
        orderNo: this.$route.params.id,
        skuId: '',
        goodsCombo: '',
        extraServiceCodeList: []
      }
    },
    updateOrderGoods() {
      updateOrderGoodsAPI(this.formData).then(() => {
        localStorage.setItem('is_update_period', 'true')
        this.$message.success('修改成功')
        this.$router.push({ name: 'ReviewDetail', params: { id: this.$route.params.id }, query: { memberId: this.$route.query.memberId } })
      })
    },
    skuChange(sku) {
      this.formData.extraServiceCodeList = []
      this.skuExtraServiceList = sku.extraServiceList
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
  }
  .search-content {
    .formData {
      background: #fafafa;
      margin-top: 20px;
      padding: 10px 0;
      .el-form-item {
        margin-bottom: 0;
        .el-form-item__label {
          color: #1a1e25;
        }
        .sku {
          display: flex;
          align-items: center;
          .sku-name {
            margin-right: 20px;
          }
          .sku-value {
            display: flex;
            align-items: center;
            .item {
              cursor: pointer;
              margin-right: 10px;
              border: 1px solid #ccc;
              padding: 2px 6px;
            }
            .el-radio-group {
              .el-radio-button {
                margin-right: 10px;
                .el-radio-button__inner {
                  border-radius: 0;
                  border: 1px solid #dcdfe6;
                  box-shadow: none;
                }
              }
            }
            span {
              border: 1px solid #dcdfe6;
              line-height: 1;
              padding: 5px 10px;
              background: #fff;
            }
          }
        }
      }
    }
  }
  .sku-list {
    background: #fafafa;
    margin-bottom: 20px;
    .item-row {
      display: flex;
      justify-content: space-between;
      &:first-child {
        background-color: #f4f5f9;
        padding: 10px 0;
      }
      .item-clu {
        padding: 5px 0 5px 10px;
        flex: 3;
        display: flex;
        align-items: center;
        &:first-child {
          flex: 1;
        }
        &:nth-last-child(2) {
          flex-wrap: wrap;
        }
      }
    }
  }
  .submit-btn {
    margin-top: 20px;
  }
  .service {
    display: flex;
    margin-top: 10px;
    .left {
    }
    .right {
    }
  }
}
</style>
